<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'paragon-payment-list-paragon' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >PARAGON</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'business-payment-list-bussiness' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >BUSINESS</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>