<template>
  <div>
    <header-slot>
      <template #actions>
        <portal-target name="settings-actions" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'payments-settings' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Payments</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'human-talent-s-type-request' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Types of Claims
      </b-nav-item>
    </b-nav>

    <b-card class="border-top-primary border-3 border-table-radius px-0 pb-1">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
